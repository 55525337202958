<template>
  <u-form class="relative" :state="organization" :schema="schema" @submit="save">
    <u-form-group label="Organization Name" name="name">
      <u-input placeholder="Name of Organization" v-model="organization.name" required />
    </u-form-group>

    <u-form-group label="Contact Person" class="my-6" hint="Optional">
      <div class=" grid-cols-3 gap-2">
        <u-form-group name="contact_person_first_name" class="mb-2">
          <u-input placeholder="First Name" v-model="organization.contact_person_first_name" />
        </u-form-group>
        <u-form-group name="contact_person_middle_name" class="mb-2">
          <u-input placeholder="Middle Name(s)" v-model="organization.contact_person_middle_name" />
        </u-form-group>
        <u-form-group name="contact_person_last_name" class="mb-2">
          <u-input placeholder="Last Name" v-model="organization.contact_person_last_name" />
        </u-form-group>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 gap-2">
        <u-form-group name="contact_person_email">
          <u-input placeholder="Email Address (if different from login email)" v-model="organization.contact_person_email" />
        </u-form-group>
        <u-form-group name="contact_person_phone_number" class="mb-2">
          <u-input placeholder="Phone Number" v-model="organization.contact_person_phone_number" />
        </u-form-group>
      </div>
    </u-form-group>

    <u-button
      color="charcoal"
      :trailing-icon="showAllDetails ? 'i-ri-arrow-up-s-line' : 'i-ri-arrow-down-s-line'"
      variant="link"
      size="xl"
      :padded="false"
      @click="showAllDetails = !showAllDetails"
      label="Add Additional Details"
    />

    <transition name="f-slide-fade">
      <div v-if="showAllDetails">
        <u-form-group label="Description" class="my-6">
          <u-textarea
            v-model="organization.bio"
            placeholder="Add a short description of the organization."
            :rows="4"
            :maxrows="12"
            autoresize
          />
        </u-form-group>

        <u-form-group label="Status" class="my-6">
          <u-radio-group
            v-model="organization.organization_status"
            :options="Object.values(USER_ORGANIZATION_STATUSES)"
            :ui="{fieldset: 'flex gap-6'}"
            :ui-radio="{inner: 'ms-1.5'}"
          />
        </u-form-group>

        <div class="grid grid-cols-2 gap-3">
          <u-form-group label="Start Date">
            <core-date-picker
              size="md"
              utc="preserve"
              v-model="organization.organization_start_date"
            />
          </u-form-group>

          <u-form-group v-if="organization.organization_status === USER_ORGANIZATION_STATUSES.inactive.value" label="End Date" :ui="organization.organization_status === USER_ORGANIZATION_STATUSES.active.value ? {label: {base: 'text-gray-400'}} : null">
            <core-date-picker
              size="md"
              v-model="organization.organization_end_date"
              utc="preserve"
              :min-date="organization.organization_start_date"
              :disabled="organization.organization_status === USER_ORGANIZATION_STATUSES.active.value"
            />
          </u-form-group>
        </div>
      </div>
    </transition>

    <!--todo move back to onboarding form-->
    <div v-if="showSubmit" class="mt-7 flex items-center flex-col justify-center">
      <u-button
        type="submit"
        size="xl"
        :loading="isSaving"
        label="Continue"
        block
        class="max-w-64"
      />
    </div>

  </u-form>
</template>

<script setup>
  import cloneDeep from 'lodash.clonedeep';
  import {object, string} from 'yup';
  import {USER_ORGANIZATION_STATUSES} from '~/constants/user.js';

  const emit = defineEmits(['updated']);
  const props = defineProps({
    showSubmit: {
      type: Boolean,
      default: true
    }
  });

  const sessionStore = useSessionStore();
  const {currentUser} = storeToRefs(sessionStore);
  const showAllDetails = ref(false);
  const isSaving = ref(false);

  const organization = ref(Object.assign(cloneDeep(currentUser.value), {
    account_type: USER_ACCOUNT_TYPES.organization.value
  }));

  const schema = object({
    name: string().required('This field is required.'),
    contact_person_email: string().email('Invalid email address.').nullable().transform(value => !value ? null : value),
    contact_person_phone_number: string().matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, 'Invalid phone number.').nullable().transform(value => !value ? null : value)
  });

  async function save() {
    isSaving.value = true;

    try {
      const orgUpdates = organization.value;
      await sessionStore.updateCurrentUser(orgUpdates);

      emit('updated');
    } catch (e) {
      useErrorToast().add();
    } finally {
      isSaving.value = false;
    }
  }

  watch(() => organization.value.organization_start_date, newVal => {
    if (organization.value.organization_status === USER_ORGANIZATION_STATUSES.inactive.value
      && organization.value.organization_end_date < newVal)
    {
      organization.value.organization_end_date = newVal;
    }
  });

  watch(() => organization.value.organization_status, newVal => {
    if (newVal === USER_ORGANIZATION_STATUSES.active.value) {
      organization.value.organization_end_date = null;
    }
  });

  defineExpose({
    save
  });

</script>
